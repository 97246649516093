<template>
  <div>
    <v-form ref="dform" lazy-validation @submit.prevent>
      <v-text-field
        v-model.trim="dform.fullname"
        :rules="nameRules"
        label="Full Name"
        counter="50"
        outlined
        required
        rounded
      ></v-text-field>

      <v-text-field
        v-model.trim="dform.email"
        outlined
        :rules="emailRules"
        label="E-mail"
        required
        rounded
      ></v-text-field>

      <v-text-field
        v-model.trim="dform.phoneno"
        outlined
        :counter="15"
        :rules="phoneRules"
        label="Phone Number"
        required
        rounded
      >
      </v-text-field>

      <v-text-field
        v-model.trim="dform.subject"
        :rules="subjectRules"
        outlined
        label="Subject"
        required
        rounded
      >
      </v-text-field>

      <v-textarea
        v-model.trim="dform.message"
        background-color="grey lighten-2"
        :rules="messageRules"
        label="message"
        outlined
        required
        rounded
      ></v-textarea>

      <v-btn
        color="primary"
        class="mr-4 button"
        @click="submitForm"
        :loading="loading"
      >
        Submit
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackColor">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "ContactForm",
  props: {
    category: {
      type: String,
      default: "general",
    },
  },
  mixins: [snackMixin],

  data() {
    return {
      dform: this.initializeForm(),
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
      ],
      messageRules: [(v) => !!v || "Message is required"],
      subjectRules: [
        (v) =>
          (v && v.length <= 50) || "Subject must be less than 50 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
    };
  },

  methods: {
    submitForm() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      apiClient
        .post("/enquiries", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = this.initializeForm();
            this.displayMsg(
              "Thanks! Your Message has been submitted successfully, We will get back to you"
            );
            this.$refs.dform.resetValidation();
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((error) => this.displayMsg(error.message, "error"));
    },
    initializeForm() {
      return {
        id: "",
        fullname: "",
        email: "",
        phoneno: "",
        subject: "",
        message: "",
        treated: 0,
        treated_by: "",
        category: this.category,
      };
    },
  },
};
</script>
