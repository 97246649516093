<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>

    <v-container class="mt-5">
      <P
        >CHRISTORE OFFICE HUB is a Co-working Offices based in Ado-Ekiti, Ekiti
        State and focuses on corporate experiences, inspiring shared office
        spaces, convenient virtual offices and well equipped private offices.
        The hub offers a dynamic community for people andbusiness to grow
        together.</P
      >
      <P
        >It is not just a place to work but rather a movement of innovation.
        Business center for early stage ventures that have uncertain headcount
        projections operate entirely from Co-working Offices due to the spatial
        flexibility Office.</P
      >
      <P
        >Our fully furnished office provides businesses with the opportunity to
        leverage expertise without making a capital-intensive investment. We
        also offer counseling, coaching, consulting, and training with
        essential</P
      >

      <h3 class="mt-10 text-center">OUR SERVICES</h3>
      <v-row class="mt-6" justify="center">
        <v-col>
          <v-img
            transition="scroll-x-transition"
            src="img/conference.jpg"
            height="300"
            width="500"
          ></v-img>
        </v-col>
        <v-col align-self="center">
          <v-card-title class="primary--text">
            <b> FLEXI SPACES </b></v-card-title
          >
          <p>
            Bring your own gear, set up where you like, grab a desk and start
            work, conference room available on request.
            <b>AS LOW AS 1000/MONTH</b>
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col align-self="center" order="1" order-sm="1">
          <v-card-title class="primary--text">
            <b> DEDICATED DESKS</b></v-card-title
          >
          <p>
            Your very own standard desk to mess up as much as you like with
            access to conference room, secretarial services and business
            address.
            <b> AS LOW AS 25,000/MONTH</b>
          </p>
        </v-col>
        <v-col order-sm="2">
          <v-img src="img/dedicated.png" height="300" width="500"></v-img>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col>
          <v-img src="img/executive.png" height="300" width="500"></v-img>
        </v-col>
        <v-col align-self="center">
          <v-card-title class="primary--text">
            <b>EXECUTIVE OFFICES </b></v-card-title
          >
          <p>
            Well furnished and prepared to host a number of visitors at a time,
            these offices are in different sizes and different designs to suit
            your business needs, with access to conference room, secretarial
            services and business address.
            <b> AS LOW AS 35,000/MONTH</b>
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col align-self="center" order="1" order-sm="1">
          <v-card-title class="primary--text">
            <b>SECRETARIAL SERVICE/ Virtual Offices </b></v-card-title
          >
          <p>
            Specialized and experience secretary to handle all mails and
            meetings without being physically present. Also known as the virtual
            office, the service is available to individuals away from the state
            while answering to the office demands.
            <b> AS LOW AS 7,500/MONTH</b>
          </p>
        </v-col>
        <v-col order-sm="2">
          <v-img src="img/sec.jpg" height="300" width="500"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <h3 class="text-center my-6">
      RENTALS: PROJECTORS/SCREENS, CONFERENCE ROOM AND OTHER EQUIPMENT ARE
      AVAILABLE FOR HOURLY RENTALS.
    </h3>

    <h3 class="text-center grey lighten-3 pa-8">
      NOTE* ALL PRICES ARE NEGOTIABLE WITH TERMS AND CONDITIONS
    </h3>

    <v-container>
      <h2 class="text-center mt-7">To Make More Enquries</h2>
      <v-row justify="center">
        <v-col cols="12" md="7" lg="8">
          <contact-form category="offices" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContactForm from "@/components/ContactForm.vue";
export default {
  name: "CoworkingSpaces",
  components: { BreadCrumb, ContactForm },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        link: "Home",
      },
      {
        text: "Co-Working Offices",
        disabled: true,
        link: "CoworkingOffices",
      },
    ],
  }),
};
</script>
