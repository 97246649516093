export const snackMixin = {
  data() {
    return {
      snackbar: false,
      snackMsg: "",
      snackColor: "",
      loading: false,
    };
  },

  methods: {
    displayMsg(msg, color = "success", loading = "loading", state = true) {
      this.snackMsg = msg;
      this.snackColor = color;
      this.snackbar = state;
      this[loading] = false;
    },
  },
};
