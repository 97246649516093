<template>
  <div class="grey lighten-3 mb-5">
    <v-breadcrumbs :items="items" class="pl-0 py-8 justify-center">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="{ name: item.link }"
          :disabled="item.disabled"
          exact
          class="title"
        >
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    items: {
      type: Array,
    },
  },
  data: () => ({}),
};
</script>
